import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMaterial from 'vue-material'
import ProgressBar from 'vuejs-progress-bar'
import vmodal from 'vue-js-modal'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(vmodal)
Vue.use(ProgressBar)
// Vue.config.productionTip = false
// Vue.use(VueMaterial);

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
