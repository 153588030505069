<template>
  <div>
    <header class="header webview">
      <!-- Top Header Area Start -->

      <section class="top-header">
        <div class="mainmenu-area">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav class="navbar navbar-expand-lg navbar-light">
                  <a class="navbar-brand" href="/">
                    <img
                      src="../../assets/images/RFwhite.png"
                      alt=""
                      style="max-width: 16%; float: left"
                    />
                  </a>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#main_menu"
                    aria-controls="main_menu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div
                    class="collapse navbar-collapse fixed-height"
                    id="main_menu"
                  >
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item dropdown"></li>
                      <li class="nav-item"></li>
                      <li class="nav-item">
                        <a class="nav-link" href=""
                          >Staking
                          <div class="mr-hover-effect"></div>
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="https://rage.fan/about-rage/"
                          target="_blank"
                          >About
                          <div class="mr-hover-effect"></div
                        ></a>
                      </li>

                      <!-- <li class="nav-item">
                        <a class="nav-link" href=""
                          >Contact
                          <div class="mr-hover-effect"></div
                        ></a>
                      </li> -->
                    </ul>

                    <a
                      v-if="userAddress"
                      href=""
                      class="mybtn1"
                      data-toggle="modal"
                      data-target="#signin"
                      @click="connnect()"
                    >
                      <span>
                        {{
                          userAddress.substring(0, 6) +
                          "..." +
                          userAddress.substring(
                            userAddress.length - 4,
                            userAddress.length
                          )
                        }}</span
                      ></a
                    >
                    <a
                      v-else
                      href="#"
                      class="mybtn1"
                      data-toggle="modal"
                      data-target="#signin"
                      @click="connnect()"
                    >
                      Connect</a
                    >

                    <!-- <a>
                      
                      <button class="openbtn" @click="openNav()">☰</button></a> -->
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
    <header class="header mobileview">
      <section class="top-header">
        <div class="mainmenu-area">
          <img src="../assets/menu.png" alt="" @click="openNav()" />
          <img src="" alt="" />
        </div>
      </section>
    </header>
    <div id="mySidepanel" class="sidepanel" style="color: white">
      <a href="javascript:void(0)" class="closebtn" @click="closeNav()">×</a>
      <a href="#">Staking</a>
      <a href="#">About</a>
      <a href="#">Contact</a>
    </div>

    <section class="breadcrumb-area play">
      <img
        class="bc-img"
        src="../../assets/images/breadcrumb/play.png"
        alt=""
      />
      <div class="container">
        <div class="row" style="text-align: start">
          <div class="col-lg-6">
            <progress-bar :options="options1" :value="70" />
            <h5 class="title">WHAT IS $RAGE STAKING?</h5>
            <span style="color: white">
              $Rage Staking is the process of holding tokens in a cryptocurrency
              wallet to support the operations of a network. Participants are
              rewarded for depositing and holding coins, with constant
              guaranteed time-based returns. Rewards are calculated based on
              staking time: the longer you stake, the more you earn.
            </span>
          </div>
          <div class="col-lg-6"></div>
        </div>
      </div>
    </section>
    <!-- <Scroll></Scroll> -->

    <h2 class="title" style="color: rgb(180, 134, 183)">Staking Pools</h2>
    <vue-horizontal-list
      :items="items"
      :options="options"
      style="margin-top: -20px; margin: auto; width: 60%; padding: 10px"
    >
      <template v-slot:default="{ item }">
        <div>
          <section
            class="play-games"
            style="margin-top: 150px"
            @click="datapass(item)"
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="gaming-activities">
                    <div class="gaming-activities-inner">
                      <div class="row">
                        <div class="single-activities">
                          <div class="top-area">
                            <div class="left">
                              <div class="icon">
                                <h5 class="numbersize">
                                  {{ item.Maturity }}%<i
                                    class="fas fa-star"
                                  ></i>
                                </h5>
                                <br /><br />
                                <span> P.A <i class="fas fa-star"></i> </span>
                              </div>
                            </div>
                            <div class="right">
                              <!-- <img
                                  src="../../assets/images/play/icon1.png"
                                  alt=""
                                /> -->
                              <h4 class="title">{{ item.Name }}</h4>
                              <span style="color: white"
                                >{{ item.Days_until }} Days</span
                              >
                              <!-- <p
                                class="text"
                                style="
                                  font-weight: 900 !important;
                                  color: orangered;
                                "
                                v-if="item.closed"
                              >
                                Pool Status: Opening Soon
                              </p> -->
                            </div>
                          </div>
                          <div class="bottom-area">
                            <div
                              class="row"
                              style="
                                color: white;
                                font-size: small;
                                text-align: start;
                              "
                            >
                              <div class="col-sm-12">
                                <div :class="box_cls" :style="box_style" v-if="!item.closed">
                                  <!-- <div :class="label_cls">
                                    Cap RAGE
                                    <span style="float: right"
                                      >{{
                                        item.Cap_RAGE.toLocaleString()
                                      }}
                                      &nbsp;&nbsp;</span
                                    >
                                  </div>
                                  <progress-bar
                                    v-if="item.percentage > 0"
                                    size="medium"
                                    :val="item.percentage"
                                    :text="item.percentageInWords"
                                    style="color: white !important"
                                  />
                                  <progress-bar
                                    v-else
                                    size="medium"
                                    :val="item.percentage"
                                    style="color: white !important"
                                  /> -->
                                  <h5 style="text-align: center;color: green;">
                                    Staking Open
                                  </h5>
                                </div>
                                <div v-else>
                                  <h5 style="text-align: center;color: red;">
                                    Staking Closed
                                  </h5>
                                  
                                </div>
                              </div>
                              <br /><br />
                              <div class="col-sm-12 margin-adjust">
                                <span class="material-icons"> today </span>
                                <span v-if="item.Days_open">
                                  Opens at: {{ item.Start_date }}
                                  {{ item.Start_time }} UTC 
                                  ({{
                                    item.Days_open
                                  }} Days )</span
                                >
                                <span v-else>
                                  Opens at: {{ item.Start_date }}
                                  {{ item.Start_time }} UTC </span
                                >
                              </div>

                              <div class="col-sm-6 margin-adjust">
                                <span class="material-icons">
                                  stacked_bar_chart
                                </span>
                                <span>
                                  Minimum {{ item.Min.toLocaleString() }}
                                </span>
                              </div>
                              <div class="col-sm-6 margin-adjust">
                                <span class="material-icons">
                                  stacked_bar_chart
                                </span>
                                Maximum: {{ item.Max.toLocaleString() }}
                              </div>
                              <br /><br /><br />
                            </div>
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </template>
    </vue-horizontal-list>

    <!-- Breadcrumb Area End -->

    <!-- Play Games Area Start -->
    <section class="play-games">
      <div class="all-games">
        <div class="container">
          <div class="row" style="text-align: start">
            <div class="col-lg-6">
              <h4>About us</h4>
              <span class="footer-span">
                Rage.Fan is a fan-first decentralized fantasy sports platform
                where players will have the opportunity to acquire action cards
                based on NFTs to earn additional in-game points. Players will
                partake in an Augmented Reality based Token hunt platform to
                collect $RAGE coins. The platform will also host a one-of-kind
                Sports Oracle offering reliable secure off-chain sports data.
              </span>
            </div>
            <div class="col-lg-6">
              <h4>Contact us</h4>
              <span class="footer-span">
                Please contact us if you need any help. </span
              ><br /><br />
              <div>
                <a href="https://ragefan.medium.com/" target="blank">
                  <img
                    class="btm-img"
                    src="../assets/medium.png"
                    alt=""
                    style="border-radius: 19px" /></a
                >&nbsp;
                <a href="https://t.me/rage_fan" target="blank">
                  <img class="btm-img" src="../assets/twitter.png" alt="" /></a
                >&nbsp;
                <a href="https://twitter.com/RageFanSocial" target="blank">
                  <img class="btm-img" src="../assets/telegram.png" alt=""
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <modal name="errorModal" @closed="loadingResetErrorModal()">
      <br />
      <i class="fas fa-exclamation-circle fa-5x"></i>
      <br />
      <br />
      {{ errorMessage }}
    </modal>
  </div>
</template>

<script>
import "../../assets/css/bootstrap.min.css";
// import "../../assets/css/plugin.css";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
// import Scroll from "../components./views/scrollimage.vue";
import VueHorizontalList from "vue-horizontal-list";
import stakeABI from "../../src/assets/stakeABI.json";
import ProgressBar from "../components/Progress.vue";
var Web3 = require("web3");
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    ProgressBar,
    VueHorizontalList,
  },
  data() {
    return {
      options1: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2dbd2d",
          backgroundColor: "#333333",
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: "line",
        },
      },
      errorMessage: "Something Went Wrong",
      userAddress: null,
      stakeContractAddress: "0xaBCb3B00d6f56Eb7845c12bEd3316904A0b99971",
      stakeInstance: null,
      options: {
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 2 },
          { size: 3 },
        ],
      },
      items: [
        {
          id: "0",
          Name: "VIP STAKING",
          Cap_RAGE: 1000000,
          Start_date: "27/08/2021",
          Start_time: "15:00",
          Days_open: "8",
          Days_until: "75",
          Maturity: "60",
          Min: 2000,
          Max: 100000,
          percentage: 0,
          percentageInWords: "N/A",
          closed: true,
          timeStamp: 1628348400
        },
        // {
        //   id: "1",
        //   Name: "Gold Staking",
        //   Cap_RAGE: 500000,
        //   Start_date: "02/09/2021",
        //   Start_time: "15:00",
        //   Days_open: "5",
        //   Days_until: "45",
        //   Maturity: "30",
        //   Min: 2000,
        //   Max: 50000,
        //   percentage: 0,
        //   percentageInWords: "N/A",
        //   closed: false,
        // },
        {
          id: "2",
          Name: "Perpetual",
          Cap_RAGE: 500000,
          Start_date: "07/09/2021",
          Start_time: "15:00",
          Days_open: null,
          Days_until: "60",
          Maturity: "30",
          Min: 2000,
          Max: 100000,
          percentage: 0,
          percentageInWords: "0%",
          closed: true,
          timeStamp: 1631026800
        },
        // {
        //   id: "2",
        //   Name: "Gold",
        //   Cap_RAGE: 500000,
        //   Start_date: "02/09/2021",
        //   Start_time: "15:00",
        //   Days_open: "5",
        //   Days_until: "45",
        //   Maturity: "30",
        //   Min: 2000,
        //   Max: 50000,
        //   percentage: 0,
        //   percentageInWords: "0%",
        //   closed: true,
        // },
        // {
        //   id: "3",
        //   Name: "SPECIAL STAKING",
        //   Cap_RAGE: 500000,
        //   Start_date: "27/08/2021",
        //   Start_time: "15:00",
        //   Days_open: "",
        //   Days_until: "60",
        //   Maturity: "45",
        //   Min: 10000,
        //   Max: 50000,
        //   percentage: 0,
        //   percentageInWords: "0%",
        //   closed: true,
        // },
      ],
    };
  },
  methods: {
    openNav() {
      document.getElementById("mySidepanel").style.width = "50%";
    },

    closeNav() {
      document.getElementById("mySidepanel").style.width = "0";
    },
    datapass(item) {
        this.$root.$emit("metaaddress", this.userAddress);
        this.$router.push({
          name: "staking",
          params: { id: item.id, address: this.userAddress },
        });
    },
    async createStakeInstance() {
      if (typeof window.ethereum !== "undefined") {
        this.stakeInstance = new window.web3.eth.Contract(
          stakeABI,
          this.stakeContractAddress
        );
        this.getStakedAmountByPlan();
        return Promise.resolve();
      } else {
        this.showErrorModal("Staking Contract Initializition Failed");
        return Promise.reject();
      }
    },
    convertWeiToEther(wei) {
      return window.web3.utils.fromWei(wei.toString(), "ether");
    },
    async getStakedAmountByPlan() {
      try {
        let stakePlanDetails = await this.stakeInstance.methods
          .stakePlans("0")
          .call();
        let totalStakeByPlan = this.convertWeiToEther(
          stakePlanDetails.totalStaked
        );
        let maxCap = this.convertWeiToEther(stakePlanDetails.maxCap);
        console.log(`${totalStakeByPlan} and ${maxCap}`);
        let percentage = (totalStakeByPlan * 100) / maxCap;
        this.items[0]["percentageInWords"] = percentage.toFixed(2) + "%";
        this.items[0]["percentage"] = percentage;
        console.log("Percentage", percentage);
      } catch (error) {
        console.error("error reading contract", error);
      }
    },
    showErrorModal(message) {
      this.$modal.show("errorModal");
      this.errorMessage = message ? message : "Something Went Wrong";
    },

    hideErrorModal() {
      this.$modal.hide("errorModal");
      this.errorMessage = "Something Went Wrong";
    },

    loadingResetErrorModal() {
      this.errorMessage = "Something Went Wrong";
    },
    checkNetwork() {
      let self = this;
      if (window.ethereum) {
        if (window.ethereum.networkVersion.toString() != "56") {
          self.showErrorModal("Connect to Binance Chain and Continue");
          return;
        } else {
          self.createStakeInstance();
        }
      }
    },
    connnect() {
      if (typeof window.ethereum !== "undefined") {
        let self = this;
        ethereum.request({ method: "eth_requestAccounts" }).then(function () {
          window.web3 = new Web3(ethereum);
          window.web3.eth.getAccounts(function (err, accounts) {
            if (err != null) console.error("An error occurred: " + err);
            else if (accounts.length == 0)
              console.log("User is not logged in to MetaMask");
            else {
              console.log("User is logged in to MetaMask", accounts);
              self.userAddress = accounts[0];

              self.checkNetwork();
              //  litionNetwork()
              window.location.reload();
            }
          });
          return;
        });
      }
    },
    litionNetwork() {
      if (typeof window.ethereum !== "undefined") {
        let self = this;
        window.web3 = new Web3(window.ethereum);
        // console.log("is connect", window.ethereum.isConnected());
        window.web3.eth.getAccounts(function (err, accounts) {
          if (err != null) console.error("An error occurred: " + err);
          else if (accounts.length == 0) {
            console.log("User is not logged in to MetaMask");
            // self.getOptions();
          } else {
            console.log("created");
            console.log("User is logged in to MetaMask", accounts);
            self.userAddress = accounts[0];
            self.checkNetwork();
            // self.getOptions();
          }
        });
      }
    },
  },
  created() {
    this.litionNetwork();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@media only screen and (max-width: 799px) {
  .webview {
    display: none;
  }
}
@media only screen and (min-width: 800px) {
  .mobileview {
    display: none;
  }
}
.sidepanel {
  width: 0;
  position: fixed;
  z-index: 1;
  height: 250px;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidepanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidepanel a:hover {
  color: #f1f1f1;
}

.sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}
.play-games
  .gaming-activities
  .gaming-activities-inner
  .single-activities
  .top-area
  .left
  .icon {
  position: relative;
  top: 0px;
  text-align: center;
  margin-right: 30px;
}
.vue-simple-progress-text {
  color: white !important;
}
.vue-simple-progress {
  border-radius: 10px !important;
}
.vue-simple-progress-bar {
  border-radius: 10px !important;
}
.numbersize {
  font-size: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: inline-block;
  padding: 0px 10px;
  border-radius: 22px;
  top: 25px !important;
  position: relative;
  background: #252f5a;
  color: #fff;
}
.vhl-item:hover {
  background-color: #e5e8e8;
}
.footer-span {
  color: white;
  font-size: 14px;
}
.margin-adjust {
  margin-top: 20px;
}
.btm-img {
  width: 40px;
}
.vhl-btn-right {
  margin-left: auto;
  margin-right: 0px !important;
  margin-bottom: 80px;
}
.vhl-btn-left {
  margin-left: 0px !important;
  margin-right: auto;
}
.vhl-item {
  padding-left: 15px !important;
}
</style>
<style scoped>
.material-icons {
  font-family: "Material Icons";
  font-weight: 100;
  font-style: initial;
  color: darkcyan;
  font-size: 15px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  /* margin-top: 11px; */
}
.breadcrumb-area.play {
  padding: 80px 0px 100px;
}
.play-games .gaming-activities .gaming-activities-inner .single-activities {
  background: #252f5a;
  padding: 0px 19px 17px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
